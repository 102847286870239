import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.js";
import AlignedImage from "../components/aligned-image";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`About Matt`}</h1>
    <AlignedImage image="headshot.jpg" alignment="right" altText="Matt Edwards Headshot" mdxType="AlignedImage" />
    <p>{`Originally from Melbourne, I completed a Commerce Degree at Deakin University, where
I majored in Marketing and Electronic Commerce (which has grown over the past 10
years to be known as Internet Marketing).`}</p>
    <p>{`I also have an Arts Degree from Edith Cowan University in Perth, where I majored in Music Theatre at the Western Australian Academy of Performing Arts (WAAPA). I have appeared in several major musicals in Australia including `}<em parentName="p">{`CATS the Musical`}</em>{`, `}<em parentName="p">{`My Fair Lady`}</em>{` for Opera Australia, `}<em parentName="p">{`Blood Brothers`}</em>{` and the most recent Australian tour of `}<em parentName="p">{`Mamma Mia!`}</em>{`.`}</p>
    <p>{`When not performing onstage I create websites for actors, theatre productions, agents, production companies and anyone involved in the performing arts industry.`}</p>
    <p>{`I'm also more than happy to work with small businesses not directly involved in the performing arts.`}</p>
    <p>{`After a couple of years living on the shores of `}<a parentName="p" {...{
        "href": "https://www.visitlakemac.com.au/"
      }}>{`Lake Macquarie`}</a>{`, I currently live in Adelaide with my `}<a parentName="p" {...{
        "href": "http://erinjames.com.au"
      }}>{`wife`}</a>{` and two kids.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      